// import Print from "@/api/Print";
// import { useToast } from "vue-toast-notification";
// const toast = useToast();

export const setVerificationImage = ({ commit }, formData) => {
  console.log('formData', formData)
  commit("SET_VERIFICATION_IMAGE", formData);
  // commit("SET_VERIFICATION_ERROR", false)
};

