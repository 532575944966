import Api from "./Api";

const END_POINT = "document-templates";

export default {
  // get all templates
  all() {
    return Api.get(`${END_POINT}?role=Notary`);
  },

  postNotaryRequestForm(data) {
    return Api.post("request-affidavits-upload", data);
  },
};
