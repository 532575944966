export default {
  token: null,
  token_type: null,
  profile: null,
  resendOTP: null,
  OTPFlag: {},
  loader: false,
  errors: [],
  login_loader: false,
  verify_loader: false,
  verify_id_loading: false,
  verifyError: false,
  change_signer_password: false,
};
