export default {
	time_slots: [],
	errors: null,
	payment_gateways: [],
	// schedule details form data

	// schedule country
	country: [],
	states: [],
	filter_loading: false,

	// schedule details after form submission
	schedule_details_loading: false,
	schedule_details: {},

	// virtual_session_details in waiting room
	session_details_loading: false,
	virtual_session_details: {},
	initializedDocumentId: null,
	hasMonetaryValue: false,

	// agora
	agora: null,
};

