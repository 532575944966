import { io } from "socket.io-client";
//
// let url = "http://localhost:5000";
// let url = "https://tonote-realtime-notifications.onrender.com";
let url = "https://tonote-video-sign.onrender.com/";

const socket = io(url, {
	transports: ["websocket", "polling"],
	autoConnect: false,
});

export default socket;
