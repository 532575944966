import Api from "./Api";

const NOTARY_LIST = "notary/notary-list";

export default {
  GetNotaryDetailsApi(id) {
    return Api.get(`${NOTARY_LIST}/${id}?role=Notary`);
  },

  GetNotariesApi() {
    return Api.get(`${NOTARY_LIST}?role=Notary`);
    // return Api.get(`${NOTARY_LIST}?per_page=10`);
  },

  FilterNotariesApi(data) {
    return Api.get(
      `${NOTARY_LIST}?role=Notary&date=${data.date}&time=${data.time}&country_id=${data.country_id}&state_id=${data.state_id}&per_page=10`
    );
  },

  ComplianceQuesApi(id) {
    return Api.get(`schedule-compliance-questions/${id}`);
  },

  ComplianceRespApi(answers) {
    return Api.put(
      `schedule-compliance-responses/${answers.answers[0].schedule_id}`,
      answers
    );
  },

  //update Notary session
  UpdateNotarySessionApi(payload) {
    return Api.put(`request-virtual-session/${payload.id}`, payload);
  },

  //update notary session
  notaryFeedbackApi(payload) {
    return Api.post(`feedback`, payload);
  },
};
